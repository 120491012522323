import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/vue';
export default {
    name: 'Screen',
    components: {
        IonButtons: IonButtons,
        IonContent: IonContent,
        IonHeader: IonHeader,
        IonMenuButton: IonMenuButton,
        IonPage: IonPage,
        IonTitle: IonTitle,
        IonToolbar: IonToolbar
    }
};
